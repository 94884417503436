import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import title_image from '../../images/titles/outsourcing.jpg'
import person from '../../images/person.png'

const pageTitle = "Outsourcing";
const pageSlug = "outsourcing";
class CloudComputing extends React.Component{
  render(){
    return(
      <Layout pageType="technology-services">
      <SEO title={pageTitle} />
      <section className="section page-title" style={{ backgroundImage: `url(${title_image})`}}>
      <div className="overlay"></div>
        <div className="container">
            <h1 className="title">{pageTitle}</h1>
            <h6 className="subtitle"></h6>
        </div>
      </section>

      <section id="p_technology_services" className="section static-page page-content">
        <div className="container">
            <div className="columns">
                <div className="column is-12 content-area">
                    <h3>
                    As the global marketplace continues to expand, competition for skilled resources and pressure for 
                    companies to continue to do more with fewer employees increases. Leading IT, executives, to turn 
                    to reliable outsourcing partners to gain a competitive advantage.
                    </h3>
                   <p>Many companies offer outsourcing services; however, very few strive to do the right thing for 
                       clients every time. Our outsourcing solutions drive incredibly high levels of performance, estimate 
                       worry regarding resource continuity and lower relevant costs.</p>
                  <p>By merging seasoned professionals and efficient management practices, we can create a flexible, 
                      scalable, and productive partnership. Whether we’re supporting new business strategies or enhancing 
                      applications, our clients have confidence that their critical business applications are in capable 
                      hands.</p> 
                    <p>Not sure where to start? We can figure that out together.</p>
                   <Link to="/contact-us" className="button green">Let’s Talk</Link>
                   <span className="break"></span>
                    <p>Our IT staffing and outsourcing experts strive to help our clients plan, develop, and deploy custom or 
                        packaged business solutions. We understand the importance of having onsite teams work directly with 
                        your team. In addition to fulfilling this need, we can guarantee you a quality service.</p>
                  <span className="break"></span>
                  <h4>Delivery Services</h4>
                  <h6>We Provide:</h6>
                  <ul>
                      <li>Proven methodologies and certified resources</li>
                      <li>Delivery options</li>
                      <li>Tools, templates, infrastructure and other jump-start project launch accelerators</li>
                  </ul>
                  <span className="break"></span>
                  <h4>Outsourcing Services </h4>
                  <p>Our team is trusted in delivering a quality and efficient outsourcing relationship to you. 
                      We can show you how outsourcing IT applications can provide capabilities and real value-adding 
                      solutions to IT services and strategic business objectives.</p>
                <span className="break"></span>
                <h4>Outsourcing Management and Maintenance Needs</h4>
                <p> Executives may find that the main bulk of application development focused on supporting business 
                    applications that aren’t necessarily creating a competitive advantage. Our outsourcing solutions 
                    help you address these concerns and reduce the total cost that is required to support your core 
                    business applications. Our experts achieve this by providing organizations with the following.</p>
                <span className="break"></span>
                <h4>Application Support</h4>
                <ul>
                    <li>IT staffing</li>
                    <li>Application support services</li>
                    <li>Manual and automated testing assistance </li>
                </ul>
                </div>
                {/*<div className="column is-3 sidebar">
                    <div className="person-info">
                        <img src={person} />
                        <div className="inner">
                            <h2 className="name">Greg Klem</h2>
                            <p className="status">Vice President</p>
                            <p>National Practice Lead - Business </p>
                            <p>Consulting Services</p>
                        </div>
                        <p>Not sure where to start, our team is always ready to help.</p>
                        <Link to="/" className="button green">Talk with a professional</Link>
                    </div>
                </div>*/}
            </div>
        </div>
      </section>
      </Layout>
    )
  }
}

export default CloudComputing
